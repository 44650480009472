import React from 'react'
import Layout from '../components/layout'
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

const Services = () => (
  <Layout>
    <div className="mdl-grid">
      <div className="mdl-cell mdl-cell--12-col" id="Services">
        <div className="mdl-card mdl-shadow--2dp" style={{ width: 'auto' }}>
          <div className="mdl-card__title mdl-card--expand">
            <div className="mdl-cell mdl-cell--12-col">
              <h2 className="page-header" style={{ padding: '5px' }}>
                Our Services
              </h2>
              <p>
                SCD can provide a wide variety of claims- and insurance-related
                services for your company. These include, but are not limited
                to, the following:
              </p>
              <ul>
                <li>
                  Performing workers' compensation, liability, and/or property
                  claims audits. The claims administrators could include
                  insurers, third party administrators (TPAs), or
                  self-administered programs.
                </li>
                <li>
                  Performing claims process efficiency studies to identify
                  efficient and inefficient processes, and provide
                  recommendations as needed to close any gaps.
                </li>
                <img
                  className="aboutImg"
                  style={{ float: 'right', maxWidth: '400px' }}
                  src="/Man_helping_another_to_mountaintop.jpg"
                  alt="Helping others"
                />
                <li>
                  Assisting clients in the selection of TPAs, medical bill
                  review firms, or other claims-related vendors. This process
                  includes interviewing key client personnel to determine their
                  needs, preparing the Request for Proposal (RFP) to define the
                  service requirements, leading or participating in pre-bid
                  conferences, identifying several potential bidders for RFP
                  distribution, reviewing proposals submitted, preparing scoring
                  matrices, asking and responding to additional questions as
                  needed, comparing the proposed fees of each respondent, and
                  assisting the client in selecting the vendor that best meets
                  its needs. If desired, we can also assist in the implemention
                  or transition process.
                </li>
                <li>
                  Performing due diligence examinations in preparation of
                  acquisition or divestiture, to assess the reserving processes
                  and procedures, and to determine the reasonableness of the
                  case reserves.{' '}
                </li>
                <li>
                  Creating claims performance standards, preparing claims
                  procedures manuals, and/or developing Quality Assurance
                  programs for insurers, TPAs, risk pools, or self-administered
                  programs.{' '}
                </li>
                <li>
                  Providing expert testimony relative to claims management
                  practices, reserving procedures, excess reporting
                  requirements, settlement evaluations and settlements, and
                  other claims-related topics.{' '}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
export default Services
